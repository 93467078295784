<template>
  <el-menu-item
    :index="index"
  >
    <router-link
      class="menu-router-link"
      :to="route"
    >
      <slot></slot>
    </router-link>
  </el-menu-item>
</template>

<script>

export default {
  name: "menu-item",
  components: {},

  props: {
    index: {type: String},
    route: {type: Object},
    label: {type: String},
  },

  watch: {},
  computed: {},
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {

  }
}
</script>


<style lang="scss">
@import "node_modules/element-ui/packages/theme-chalk/src/common/var.scss";
@import "@/assets/css/project-variables";

.menu-router-link{
  text-decoration: none;
  color: $--color-text-secondary;
}
</style>